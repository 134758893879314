import React, { useEffect } from 'react'
import { useDispatch, useSelector } from '@local/Store/storeContext'
import { IDinRadgivare } from '@local/DinRadgivare/DinRadgivare.types'
import { getRadgivare } from '@local/DinRadgivare/DinRadgivare.actions'
import { loaded } from '@local/DinRadgivare/DinRadgivare.selectors'

import DinRadgivare from '../DinRadgivare/DinRadgivare.container'

const YourCounselling = ({ content }: IDinRadgivare) => {
  const dispatch = useDispatch()

  const contentLoading = !('urlKey' in content)
  const radgivareLoaded = useSelector(loaded)

  useEffect(() => {
    dispatch(getRadgivare())
  }, [dispatch])
  return !contentLoading && radgivareLoaded ? <DinRadgivare content={content} /> : null
}

export default YourCounselling
