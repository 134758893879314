import { useSelector } from '@local/Store/storeContext'
import { withHooks } from '@local/Utils/Hooks'

import { DinRadgivare } from './DinRadgivare'
import { dinradgivare } from './DinRadgivare.selectors'

const DinRadgivareSelectors = () => ({
  dinradgivare: useSelector(dinradgivare),
})

export default withHooks({ DinRadgivareSelectors })(DinRadgivare)
