import { includes } from 'ramda'
import getConfig from '@local/Utils/getConfig'
import { Dispatch } from 'redux'
import { getOIDCUser } from '@trr/app-shell-communication'

import { IFetchRequestType, IRequestType } from './Network.types'

/* eslint-disable */

export const baseUrl = (url: string) =>
  url.slice(-4) === 'json' // if request is a json file, retrieve it from public
    ? `${process.env.PUBLIC_URL}${url}`
    : `${getConfig().API_URL}${url}`

// construct headers for request
const requestHeaders = ({ id_token, body, method, multipart }: any) => {
  // Directly forcing a 'multipart/form-data' will not automatically generate boundaries
  // If file is provided, dont set content-type and content-type and boundaries will automatically be generated
  const headerContentType = multipart ? {} : { 'Content-type': 'application/json' }
  return {
    headers: {
      Authorization: `Bearer ${id_token}`,
      'Accept-Language': 'sv',
      ...headerContentType,
      ...shouldUseCache(),
    },
    method,
    body,
  }
}

// append header to bypass cache if cookie set
export const shouldUseCache = () => (sessionStorage.getItem('trr-force-pass') === null ? '' : { 'x-trr-force-pass': true })

// Handle HTTP errors since fetch won't.
export const handleResponse = (response: any) => {
  const acceptedStatusCodes = [202, 200, 201, 204]
  const { status } = response

  if (includes(status, acceptedStatusCodes)) {
    return response
  }
  throw new Error(status)
}

// fetch request
export const fetchRequest = ({ body, method, id_token, _fetch = fetch, requestUrl, multipart, absolutePath }: IFetchRequestType) => {
  if (absolutePath) {
    return _fetch(absolutePath)
  }
  return _fetch(baseUrl(requestUrl), requestHeaders({ id_token, body, method, multipart }))
}

// base for all requests
const baseRequest: any =
  ({ method }: { method: string }) =>
  ({
    _fetch = fetch,
    aborted,
    appendSub = false,
    body,
    fulfilled,
    initiated,
    rejected,
    url,
    multipart,
    absolutePath,
    appendUrl = '',
  }: IRequestType) =>
  async (dispatch: Dispatch) => {
    dispatch(initiated())

    const {
      id_token,
      profile: { sub },
    } = getOIDCUser()

    try {
      // find better way to catch
      const response = await fetchRequest({
        _fetch,
        method,
        body,
        id_token,
        requestUrl: appendSub ? `${url}/${sub}${appendUrl}` : `${url}${appendUrl}`,
        multipart,
        absolutePath,
      })
      const handled = await handleResponse(response)

      if (includes(method, ['PUT', 'DELETE'])) {
        return dispatch(fulfilled())
      }

      const json = await handled.json()
      return dispatch(fulfilled(json))
    } catch (error) {
      if (aborted && error.name === 'AbortError') {
        return dispatch(aborted())
      }
      return dispatch(rejected(error))
    }
  }

export const get = baseRequest({ method: 'GET' })
export const patch = baseRequest({ method: 'PATCH' })
export const post = baseRequest({ method: 'POST' })
export const deleteRequest = baseRequest({ method: 'DELETE' })
export const put = baseRequest({ method: 'PUT' })
