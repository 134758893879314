import React, { FunctionComponent } from 'react'
import { curry } from 'ramda'

/* eslint-disable */
const withHooks = curry(
  (hooks: Record<string, any>, Component: FunctionComponent<any>) => {
    if (!Component) {
      throw new Error('Component must be provided to compose')
    }

    if (!hooks) {
      return Component
    }

    return (props: any) => {
      const hooksObject: Record<string, any> =
        typeof hooks === 'function' ? hooks(props) : hooks

      const hooksProps = Object.entries(hooksObject).reduce<
        Record<string, any>
      >((acc, [hookKey, hook]): Record<string, any> => {
        let hookValue: Record<string, any> = hook()

        if (Array.isArray(hookValue) || typeof hookValue !== 'object') {
          hookValue = { [hookKey]: hookValue }
        }

        Object.entries(hookValue).forEach(([key, value]) => {
          acc[key] = value
        })

        return acc
      }, {})

      const compoundProps = { ...props, ...hooksProps }
      return <Component {...compoundProps} />
    }
  }
)

export default withHooks
/* eslint-enable */
