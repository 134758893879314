import React from 'react'

import { IDinRadgivare } from './DinRadgivare.types'
import { IngenTilldeladRadgivare, OppenRadgivning, Radgivare } from './Fragments'

export const DinRadgivare = ({ content, dinradgivare: { ingenTilldeladRadgivare, oppenRadgivning, radgivare } }: IDinRadgivare) => {
  if (!ingenTilldeladRadgivare && !oppenRadgivning && !radgivare) {
    return null
  }

  return (
    <div data-testid="din-radgivare">
      {ingenTilldeladRadgivare && <IngenTilldeladRadgivare content={content.fordelningPagar} />}
      {oppenRadgivning && <OppenRadgivning content={content.oppenRadgivning} />}
      {!oppenRadgivning && !ingenTilldeladRadgivare && (
        <Radgivare radgivare={radgivare} radgivareNamnText={content.radgivareTilldelad.radgivareNamnText} />
      )}
    </div>
  )
}
