import React from 'react'
import { formatPhoneNumberSE } from '@local/Utils/Helpers'
import { Avatar, Card, CardContent, CardHeader, Link, Typography } from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import { IRadgivareData } from '../DinRadgivare.types'

interface Props {
  radgivareNamnText: string
  radgivare: IRadgivareData
}

export const Radgivare = ({ radgivareNamnText, radgivare }: Props) => (
  <Card variant="elevation" elevation={0} sx={{ p: 0 }} data-testid="tilldelad-radgivare">
    <CardHeader
      sx={{ p: 0 }}
      avatar={
        <Avatar sx={{ bgcolor: 'secondary.light' }}>
          <AccountCircleIcon sx={{ color: 'secondary.dark' }} />
        </Avatar>
      }
      titleTypographyProps={{ variant: 'body1' }}
      title={`${radgivare?.fornamn} ${radgivare?.efternamn}`}
      subheaderTypographyProps={{ variant: 'body2' }}
      subheader={radgivareNamnText}
    />
    <CardContent sx={{ pl: 7 }}>
      {radgivare.epost && (
        <Link href={`mailto:${radgivare.epost}`}>
          <Typography variant="body2">{radgivare.epost}</Typography>
        </Link>
      )}
      <Typography variant="body2">{formatPhoneNumberSE(radgivare?.telefon)}</Typography>
    </CardContent>
  </Card>
)
