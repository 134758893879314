/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Action, createActions } from 'redux-actions'
import { get } from '@local/Utils/Network'
import { AnyAction } from 'redux'

export const {
  getRadgivareInitiate,
  getRadgivareSuccess,
  getRadgivareFailure,
} = createActions({
  getRadgivareInitiate: () => ({}),
  getRadgivareSuccess: (radgivare) => radgivare,
  getRadgivareFailure: () => ({}),
})

export const getRadgivare = (): Action<AnyAction> =>
  get({
    initiated: getRadgivareInitiate,
    fulfilled: getRadgivareSuccess,
    rejected: getRadgivareFailure,
    url: '/arende/klient',
    appendUrl: '/radgivare',
    appendSub: true,
  })
