import { handleActions } from 'redux-actions'

import {
  getRadgivareInitiate,
  getRadgivareSuccess,
  getRadgivareFailure,
} from './DinRadgivare.actions'

export const initialState: { loaded: boolean } = {
  loaded: false,
}

const dinRadgivareReducer = handleActions(
  {
    [getRadgivareInitiate.toString()]: (state) => ({
      ...state,
      loaded: false,
    }),
    [getRadgivareSuccess.toString()]: (state, action) => ({
      ...state,
      ...action.payload,
      loaded: true,
    }),
    [getRadgivareFailure.toString()]: (state) => ({
      ...state,
      loaded: false,
    }),
  },
  initialState
)

export default dinRadgivareReducer
