import React from 'react'
import { formatPhoneNumberSE } from '@local/Utils/Helpers'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import { Avatar, Card, CardContent, CardHeader, Link, Typography } from '@mui/material'

import { IFordelningPagarContent } from '../DinRadgivare.types'

interface Props {
  content: IFordelningPagarContent
}

export const IngenTilldeladRadgivare = ({ content }: Props) => (
  <Card variant="elevation" elevation={0} sx={{ p: 0 }} data-testid="ingen-tilldelad-radgivare">
    <CardHeader
      sx={{ p: 0 }}
      avatar={
        <Avatar sx={{ bgcolor: 'secondary.light' }}>
          <AccessTimeIcon sx={{ color: 'secondary.dark' }} />
        </Avatar>
      }
      titleTypographyProps={{ variant: 'body1' }}
      title={content.header}
      subheaderTypographyProps={{ variant: 'body2' }}
      subheader={content.description}
    />
    <CardContent sx={{ pl: 7 }}>
      {content.email && (
        <Link href={`mailto:${content.email}`}>
          <Typography variant="body2">{content.email}</Typography>
        </Link>
      )}
      <Typography variant="body2">{formatPhoneNumberSE(content.phone)}</Typography>
    </CardContent>
  </Card>
)
