import { createContext } from 'react'
import {
  createStoreHook,
  createDispatchHook,
  createSelectorHook,
  ReactReduxContextValue,
} from 'react-redux'

export const RadgivareContext =
  createContext<ReactReduxContextValue<unknown>>(null)

export const useStore = createStoreHook(RadgivareContext)
export const useDispatch = createDispatchHook(RadgivareContext)
export const useSelector = createSelectorHook(RadgivareContext)
