import { isNil } from 'ramda'
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber'

export const formatPhoneNumberSE = (number: string): string => {
  if (!isNil(number)) {
    const phoneUtilInstance = PhoneNumberUtil.getInstance()
    const _number = phoneUtilInstance.parseAndKeepRawInput(number, 'SE')
    return (
      phoneUtilInstance
        .format(_number, PhoneNumberFormat.NATIONAL)
        // add spaces between hyphons
        ?.split('-')
        ?.join(' - ')
    )
  }
  return ''
}
