import React from 'react'
import { Provider } from 'react-redux'
import { AppShellRouter, KeyRoute } from '@local/src/AppShellRouter'
import { YourCounselling } from '@local/src/Pages'
import { ThemeProvider, createTheme } from '@mui/material'
import { defaultLocale, themeOptions } from '@trr/mui-theme'

import { IAppShellConfiguration } from './App.types'
import Store from './Store/store'
import { RadgivareContext } from './Store/storeContext'
import { AppShellContext } from './AppShellContext'

const App = ({ content, currentKey, enabledFeatures }: IAppShellConfiguration) => {
  const theme = createTheme(themeOptions, defaultLocale)

  const dinRadgivare = 'din-radgivare'
  return (
    <ThemeProvider theme={theme}>
      <Provider store={Store} context={RadgivareContext}>
        <AppShellContext.Provider value={{ enabledFeatures }}>
          <AppShellRouter currentKey={currentKey}>
            <KeyRoute urlKey={dinRadgivare}>
              <YourCounselling content={content} />
            </KeyRoute>
          </AppShellRouter>
        </AppShellContext.Provider>
      </Provider>
    </ThemeProvider>
  )
}

export default App
